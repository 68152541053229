.mobile-menu.show {
    display: block; /* Show the menu when it has the 'show' class */
  }
 .gallery-section {
    padding: 20px;
    text-align: center;
  }
  
  .section-subtitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  

  
  .card {
    width: 100%;
    max-width: 400px;
  
  }
  
  .card-image {
    width: 100%;
    text-align: center;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  
  }
  
  .card-info {
    margin-top: 10px;
  }
  

  