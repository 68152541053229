.bike-customizer{
    display: flex;
    width: 100%;
    margin: 160px 20px;
}

.line-customizer-left{
    width: 50%;
    padding-left: 60px;
}
.line-customizer-right{
  width: 50%;
  padding-right: 20px;
}

@media (max-width: 992px) {
  
  .line-customizer-left{
    padding-left: 40px;
  }
  .line-customizer-right{
    padding-left: 20px;
  }

}

@media (max-width: 768px) {

  .line-customizer-left{
    padding-left: 20px;
  }
  .line-customizer-right{
    padding-left: 10px;
  }

}

@media (max-width: 360px) {
  
  .line-customizer-left{
    padding-left: 10px;
  }
  .line-customizer-right{
    padding-left: 0px;
  }

}


.bike-builder-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }
  
  .dropdown-section {
    flex: 1;
    padding-right: 50px;
  }
  
  .image-section {
    flex: 1;
  }
  
  .bike-image {
    max-width: 100%;
    height: auto;
  }
  
  .dropdown-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .sel_line{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 20px 10px;
    font-size: 12px;
    line-height: 38px;
    color: #444;
    height: 38px;
    -webkit-font-smoothing: antialiased;
    font-family: gotham_memdiumregular;
    border: 1px solid #ddd;
    background: #f8f8f8;
    position: relative;
    padding: 0 10px;
    width: 100%;
  }