
.Hello {
  text-align: start !important;
}
/* Mobile Styles */
@media (max-width: 576px) {
  .contact-info {
    max-width: 100%;
    padding: 20px;
  }
  .largest {
    width: 100% !important;
    text-align: center;
  }

  .contact-contanier {
    display: block !important;
  }

  .contact-form {
    margin-top: 20px;
  }

  .contact-form button {
    width: 100%;
    padding: 15px;
  }
}

/* Tablet (Portrait) Styles */
@media (min-width: 577px) and (max-width: 768px) {
  .contact-info {
    max-width: 100%;
    padding: 20px;
  }
  .largest {
    width: 100% !important;
    text-align: center;
  }

  .contact-contanier {
    display: block !important;
  }

  .contact-form {
    margin-top: 20px;
  }
}

/* Tablet (Landscape) Styles */
@media (min-width: 769px) and (max-width: 992px) {
  .contact-info {
    max-width: 100%;
    padding: 20px;
  }
  .contact-form {
    justify-content: center;
    width: 100%;
    margin-left: 250px;
  }
  .largest {
    width: 100% !important;
    text-align: center !important;
  }

  .contact-contanier {
    display: block !important;
  }

  .contact-form {
    margin-top: 30px;
  }
}

/* Small Desktop Styles */
@media (min-width: 993px) and (max-width: 1024px) {
  .contact-info,
  .contact-form {
    max-width: 80%;
    padding: 40px;
  }
}

/* Medium Desktop Styles */
@media (min-width: 1025px) and (max-width: 1200px) {
  .contact-info,
  .contact-form {
    max-width: 70%;
    padding: 40px;
  }
}

/* Large Desktop Styles */
@media (min-width: 1201px) and (max-width: 1440px) {
  .contact-info,
  .contact-form {
    max-width: 60%;
    padding: 50px;
  }
}

/* Extra Large Desktop Styles */
@media (min-width: 1441px) {
  .contact-info,
  .contact-form {
    max-width: 50%;
    padding: 50px;
  }
}
.sub-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.sub li {
  margin-bottom: 25px;
  margin-top: 20px;
}
.sub a {
  color: #000;
}

/* https://corehandf.com/wp-content/themes/core-health/dist/images/blog-banner.png */
.single-banner {
  background: #2e2e2e
    url("https://corehandf.com/wp-content/themes/core-health/dist/images/blog-banner.png")
    center center no-repeat !important;
}
.largest {
  text-align: center;
  width: 1050px;
}
.contact-form1{
  width: 100%;
  max-width: 900px; 
  margin: 0 auto;
  padding: 1px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
}

.contact-form1 > div {
  flex: 1 1 calc(50% - 20px); /* Adjusts the width to be half of the container minus the gap */
  box-sizing: border-box;
  margin-bottom: 0; /* Remove bottom margin to avoid extra space */
}

.contact-form1 label {
  display: block;
  margin-bottom: 5px;
  text-align: start;
  font-size: 14px;
}

.contact-form1 input,
.contact-form1 select,
.contact-form1 textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.contact-form1 input:focus,
.contact-form1 select:focus,
.contact-form1 textarea:focus {
  border-color: #007bff;
  outline: none;
}

.horizontal-group {
  /* Align children in a row */

  width: 100%; /* Ensure the container spans the full width */
}

.horizontal-group > div {
  flex: 1;
  margin-bottom: 20px; /* Make the children take up equal space */
}

.horizontal-group select {
  width: 100%; /* Ensure select elements take up the full width of their container */
}
.hs-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs_submit a {
  padding-left: 80px;
  padding-right: 80px;
}
/* App.css */

.swiper-pagination {
  position: absolute;
  bottom: 40px; /* Adjusts the vertical position */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;

}

.swiper-pagination-bullet {
  background: #ffff;
  ; /* Semi-transparent gray */
  width: 30px; /* Width of each bullet */
  height: 6px; /* Height of each bullet */
  border-radius: 0px; /* Makes the bullets rectangular with rounded edges */
  margin: 0 5px; /* Spacing between bullets */
  transition: background 0.3s ease; /* Smooth transition effect */
}

.swiper-pagination-bullet-active {
  background: #0aadf0 !important ; /* Darker color for the active bullet */
}


img{
  opacity: 1 !important;
}
.video-carousel-container {
  width: 80%;
  margin: 40px auto; /* Center carousel and add spacing from top */
}

.video-item {
  position: relative;
  padding: 20px;
  outline: none; /* Remove default focus outline */
  text-align: center;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; /* Background color for video loading time */
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-caption {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.image-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.image-item {
  text-align: center;
}

.image-item img {
  max-width: 150px;
  height: auto;
}

.image-caption {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.slick-prev:before,
.slick-next:before {
  color: #333; /* Color for navigation arrows */
}

.custom-ttl {
  text-transform: uppercase;
  color: #0aadf0;
  font-family: gotham_boldregular;
  font-size: 20px;
}

.sel_frame_img,
.sel_line_img,
.sel_rim_img,
.sel_shroud_img,
.sel_upholstery_img {
  position: absolute;
}

.sel_line_img {
  z-index: 1;
}

.sel_frame_img {
  z-index: 2;
}

.sel_upholstery_img {
  z-index: 3;
}

.sel_shroud_img {
  z-index: 4;
}

.sel_rim_img {
  z-index: 5;
}

.line-customizer select option:selected {
  background: #d0d0d0;
  color: #444;
}

.line-customizer select {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 20px 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  -webkit-font-smoothing: antialiased;
  font-family: gotham_memdiumregular;
  border: 1px solid #ddd;
  background: #f8f8f8;
  position: relative;
  padding: 0 10px;
  width: 100%;
}

.line-customizer .line-customizer-left,
.line-customizer .line-customizer-right {
  width: 49.9%;
  display: inline-block;
  vertical-align: top;
  float: left;
}

.line-customizer:after {
  content: " ";
  display: block;
  clear: both;
  float: none;
}

.images-wrapper {
  position: relative;
  display: block;
  height: 400px;
  width: 400px;
  margin: 0 auto;
}

.line-customizer .sel_frame,
.line-customizer .sel_rim,
.line-customizer .sel_shroud,
.line-customizer .sel_upholstery {
  display: none;
}

@media (max-width: 1000px) {
  .line-customizer .line-customizer-left,
  .line-customizer .line-customizer-right {
    width: 100%;
  }
}

.parrentshow.line-empty {
  display: none !important;
}

.line-customizer .sel_shroud,
.line-customizer .sel_frame,
.line-customizer .sel_upholstery,
.line-customizer .sel_rim {
  display: none;
}

.hidd,
.parenthidd {
  display: none !important;
}

.visib {
  display: inline-block !important;
}

.t-header {
  min-height: 548px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mb-4{
  margin-bottom: 20px;
}
.mt-4{
  margin-top: 20px;
}
.mobile-menu {
  display: none; /* Hide the menu by default */
}

.mobile-menu.show {
  display: block; /* Show the menu when it has the 'show' class */
}
/* .gallery-section {
  padding: 20px;
  text-align: center;
}

.section-subtitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.swiper-container {
  width: 70%;
  padding-top: 20px;
  padding-bottom: 50px;
}

.card {
  width: 100%;
  max-width: 400px;

}

.card-image {
  width: 100%;
  text-align: center;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

}

.card-info {
  margin-top: 10px;
}

 */
.px-4{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.quote-form{
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.CULTURE{
  background: #2e2e2e url('//corehandf.com/wp-content/uploads/2020/10/Our-Culture_Page_Header_cyan-1-scaled.jpg') center center no-repeat !important;
  background-size: cover;
  height: 400px; 
  display: flex;
  align-items: center;
  color: #fafafa;/* Optional: Ensures the image covers the entire div */
}
.RESPONSIBILITY{
  background: #2e2e2e url('https://corehandf.com/wp-content/uploads/2021/04/CSR-Page_Header-scaled.jpg') center center no-repeat !important;
  background-size: cover;
  height: 400px; 
  display: flex;
  align-items: center;
  color: #fafafa;/* Optional: Ensures the image covers the entire div */
}
.about-usbanner{
  background: #2e2e2e url('https://corehandf.com/wp-content/uploads/2020/10/About_Us-Page_Header_cyan-1-scaled.jpg') center center no-repeat !important;
  background-size: cover;
  height: 400px; 
  display: flex;
  align-items: center;
  color: #fafafa;
}
.mb-250{
  margin-bottom: 300px !important;
}
.btn-blue{
  background-color: #0aadf0 ;
  color: #fafafa;
}
